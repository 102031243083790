/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-01-26 13:33:18
 * @LastEditors: wenwen
 * @LastEditTime: 2024-05-05 16:32:10
 */
import {
  getCourseTreeApi,
  addAndEditCourseGroupTreeApi,
  deleteCourseGroupTreeApi,
  courseFindByPageListApi,
  addCourseApi,
  deleteCourseApi,
  getCourseGroupDetailApi
} from '@/api/course'
import { getFindCustomizeTypeApi } from '@/api/settings'
export default {
  data () {
    return {
      tableData: [],
      searchForm: {
        courseName: '', // 课程名称
        courseCode: '', // 课程编号
        courseTypeId: '', // 课程类型
        courseTypeName: '',
        updateTime: '', // 更新时间
        groupId: '',
        status: '', // 发布状态
        page: 1,
        pageSize: 10,
        total: 10
      },
      publishStatusList: [
        {
          label: '已发布',
          value: '1'
        },
        {
          label: '未发布',
          value: '2'
        }
      ], // 发布状态
      courseTypeList: [], // 课程类型
      treeData: [],
      treeDataGroup: [],
      defaultProps: {
        children: 'children',
        label: 'groupName'
      },
      tableTotal: 0,
      addGroupShow: false,
      editGroupShow: false,
      previewFileShow: false,
      uploadFileShow: false,
      deleteGroupShow: false,
      addCourseShow: false,
      currentNode: null,
      editTreeData: [],
      type: null
    }
  },
  created () {
    /**
     * 1:线上
     * 2:线下
     */
    this.type = this.$route.name === 'onlineCourse' ? 1 : this.$route.name === 'offlineCourse' ? 2 : null
    this._getCourseTree()
    this._query()
    this.getCourseType()
  },
  methods: {
    _deleteCourse (row) {
      // 列表删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCourseApi([
          row.id
        ]).then(res => {
          if (res.code === 200) {
            this._query()
          }
        })
      })
    },
    _submiCourse (item) {
      // 新增课程编辑提交

      this.courseTypeList.forEach(V => {
        if (V.id === item.courseTypeId) {
          item.courseTypeName = V.categoryName
        }
      })
      addCourseApi({
        ...item,
        type: this.type,
        status: item.status ? 1 : 2
      }).then(res => {
        if (item.butType == 2) {
          // 继续编辑
          this.$router.push({
            path: '/editCourse',
            query: {
              id: res.data.id,
              type: this.type
            }
          })
          this.$message.success('操作成功！')
        }
        this._query()
        this.addCourseShow = false
      })
    },
    _clickGroupNode (data) {
      this.searchForm.groupId = data.id
      this.searchForm.page = 1
      this._query()
    },
    _query (type) {
      // 课程查询
      courseFindByPageListApi({ ...this.searchForm, type: this.type }).then(
        (res) => {
          if (res.code === 200) {
            this.tableData = res.object.results
            this.searchForm.total = res.object.total
          }
        }
      )
    },
    getCourseType () {
      // 课程类型
      getFindCustomizeTypeApi({ type: 0 }).then((res) => {
        if (res.code === 200) {
          this.courseTypeList = res.data.scheduleType
        }
      })
    },
    _getCourseTree () {
      // 获取树形接口
      getCourseTreeApi({
        type: this.type
      }).then((res) => {
        if (res.code === 200) {
          this.treeData = JSON.parse(JSON.stringify(res.data))
          this.treeDataGroup = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    _courseDetail (row) {
      this.$router.push({
        path: '/editCourse',
        query: {
          id: row.id,
          type: this.type
        }
      })
    },
    _editGroupHandle (node) {
      const tempGroup = JSON.parse(JSON.stringify(this.treeData))
      // 分组不能选择子集分组，将当前分组的子集清空--start
      tempGroup.forEach((item, index) => {
        if (node.data.id === item.id) {
          item.children = []
        }
        if (item.children && item.children.length !== 0) {
          item.children.forEach((itemChild, childIndex) => {
            if (node.data.id === itemChild.id) {
              itemChild.children = []
            }
          })
        } else {
          item.children = []
        }
      })
      this.treeDataGroup = tempGroup
      // 分组不能选择子集分组，将当前分组的子集清空--end
      // 资源分组编辑
      getCourseGroupDetailApi({
        id: node.id
      }).then(res => {
        if (res.code === 200) {
          this.currentNode = JSON.parse(JSON.stringify(node.data))
          this.editGroupShow = true
          // this.$message.success('操作成功！')
        }
      })
    },

    _editSubmitGroup (item) {
      // 分组编辑提交
      const obj = {
        ...item,
        id: item.id,
        groupNames: [item.groupName],
        type: this.type
      }
      addAndEditCourseGroupTreeApi(obj).then((res) => {
        if (res.code === 200) {
          this.editGroupShow = false
          this._getCourseTree()
          this.$message.success('操作成功！')
        }
      })
    },
    _addGroupHandle () {
      // 分组添加
      this.addGroupShow = true
    },
    _submiAddGroup (item) {
      // 分组添加的提交
      const groupNameArr = []
      item.groupNameList.forEach((item) => {
        groupNameArr.push(item.groupName)
      })
      const obj = {
        ...item,
        groupNames: groupNameArr,
        type: this.type
      }
      addAndEditCourseGroupTreeApi(obj).then((res) => {
        if (res.code === 200) {
          this.addGroupShow = false
          this._getCourseTree()
          this.$message.success('操作成功！')
        }
      })
    },
    _deleteGrouphandle (node) {
      // 资源分组删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCourseGroupTreeApi([node.data.id]).then((res) => {
          if (res.code === 200) {
          // this.deleteGroupShow = true;
            this._getCourseTree()
            this.$message.success('操作成功！')
          }
        })
      })
    },
    _addCoursePopup (type) {
      // 新增课程
      this.addCourseShow = true
      this.type = type || ''
      if (this.$route.name == 'courseManage') {
        this._getCourseTree()
      }
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._query()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._query()
    },
    _reset () {
      // 重置
      this.searchForm = {
        courseName: '', // 课程名称
        courseId: '', // 课程编号
        courseType: '', // 课程类型
        updateTime: '', // 更新时间
        publishStatus: '', // 发布状态
        groupId: this.searchForm.groupId,
        page: 1,
        pageSize: 10,
        total: 10
      }
      this._query()
    }
  }
}
